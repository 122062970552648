@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-size: 22px;
}

td, th {
    @apply border border-black p-2;
}

table {
    @apply w-full mb-2;
}

h2 {
    font-weight: bold;
}

button {
    @apply bg-blue-300 p-2 cursor-pointer mb-2;
}

input {
    @apply w-full bg-gray-300 p-2 mb-2;
}

table input {
    @apply w-full p-2;
}

select {
    @apply w-full bg-gray-300 p-2 cursor-pointer mb-2;
}

.table-wrapper {
    overflow-x: auto;
}
